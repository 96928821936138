import React, { useRef } from "react"

// Images
import RealCrimeDoccos from "../../images/question-one/crime.jpg"
import TrashyReality from "../../images/question-one/reality.jpg"
import PeriodDramas from "../../images/question-one/period-drama.jpg"
import NatureEpics from "../../images/question-one/nature.jpg"

const QuestionOne = ({ register, errors }) => {
  const questionOneRef = useRef(null)
  function goToNext() {
    if (questionOneRef.current) {
      questionOneRef.current.scrollIntoView()
      window.scrollBy(0, questionOneRef.current.offsetHeight + 30)
    }
  }

  return (
    <>
      <div className="bg-question-type-one" ref={questionOneRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="row no-gutters">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="question-title-hldr d-flex flex-column align-items-center">
                    <div className="question-number">1</div>
                    <h2 className="question-title my-4 text-white text-center">
                      You’re currently binge watching…
                    </h2>
                  </div>
                </div>
              </div>

              <div className="row no-gutters">
                <div className="col-12">
                  {errors?.questionOne && (
                    <span className="error d-inline-block mb-2 text-yellow">
                      Please select at least one option
                    </span>
                  )}
                </div>
                <div className="col-6">
                  <label className="checkbox-container pr-2 mb-3 w-100">
                    <input
                      name="questionOne"
                      type="radio"
                      value="Real crime doccos"
                      {...register('questionOne', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img
                          className="img-fluid"
                          src={RealCrimeDoccos}
                          alt=""
                        />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Real crime doccos
                      </p>
                    </div>
                  </label>
                </div>
                <div className="col-6">
                  <label className="checkbox-container pl-2 mb-3 w-100">
                    <input
                      name="questionOne"
                      type="radio"
                      value="Trashy reality"
                      {...register('questionOne', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={TrashyReality} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Trashy reality
                      </p>
                    </div>
                  </label>
                </div>

                <div className="col-6">
                  <label className="checkbox-container pr-2 mb-0 w-100">
                    <input
                      name="questionOne"
                      type="radio"
                      value="Period dramas"
                      {...register('questionOne', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={PeriodDramas} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Period dramas
                      </p>
                    </div>
                  </label>
                </div>
                <div className="col-6">
                  <label className="checkbox-container pl-2 mb-0 w-100">
                    <input
                      name="questionOne"
                      type="radio"
                      value="Nature epics"
                      {...register('questionOne', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={NatureEpics} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Nature epics
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="text-center mt-5">
                <span className="questions-left text-primary text-center bg-yellow px-3 py-1">
                  7 Questions Left
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionOne
