import React, { useRef } from "react"

// Images
import TheBeach from "../../images/question-two/beach.jpg"
import TheSlopes from "../../images/question-two/slopes.jpg"
import CabinInTheWoods from "../../images/question-two/cabin.jpg"
import CityBreak from "../../images/question-two/city-break.jpg"

const QuestionTwo = ({ register, errors }) => {
  const questionTwoRef = useRef(null)
  function goToNext() {
    if (questionTwoRef.current) {
      questionTwoRef.current.scrollIntoView()
      window.scrollBy(0, questionTwoRef.current.offsetHeight + 30)
    }
  }

  return (
    <>
      <div className="bg-question-type-two" ref={questionTwoRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="row no-gutters">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="question-title-hldr d-flex flex-column align-items-center">
                    <div className="question-number">2</div>
                    <h2 className="question-title my-4 text-white text-center">
                      You go on holiday to…
                    </h2>
                    {/* <span className="questions-left text-primary text-center bg-yellow px-3 py-1">
                      6 Questions Left
                    </span> */}
                  </div>
                </div>
              </div>

              <div className="row no-gutters">
                <div className="col-12">
                  {errors?.questionTwo && (
                    <span className="error d-inline-block mb-2 text-yellow">
                      Please select at least one option
                    </span>
                  )}
                </div>
                <div className="col-6">
                  <label className="checkbox-container pr-2 mb-3 w-100">
                    <input
                      name="questionTwo"
                      type="radio"
                      value="The beach"
                      {...register('questionTwo', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={TheBeach} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        The beach
                      </p>
                    </div>
                  </label>
                </div>
                <div className="col-6">
                  <label className="checkbox-container pl-2 mb-3 w-100">
                    <input
                      name="questionTwo"
                      type="radio"
                      value="The slopes"
                      {...register('questionTwo', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={TheSlopes} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        The slopes
                      </p>
                    </div>
                  </label>
                </div>

                <div className="col-6">
                  <label className="checkbox-container pr-2 mb-0 w-100">
                    <input
                      name="questionTwo"
                      type="radio"
                      value="Cabin in the woods"
                      {...register('questionTwo', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img
                          className="img-fluid"
                          src={CabinInTheWoods}
                          alt=""
                        />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Cabin in the woods
                      </p>
                    </div>
                  </label>
                </div>
                <div className="col-6">
                  <label className="checkbox-container pl-2 mb-0 w-100">
                    <input
                      name="questionTwo"
                      type="radio"
                      value="City break"
                      {...register('questionTwo', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={CityBreak} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        City break
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="text-center mt-5">
                <span className="questions-left text-primary text-center bg-yellow px-3 py-1">
                  6 Questions Left
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionTwo
