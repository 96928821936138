import React, { useRef } from "react"

// Images
import MovieNight from "../../images/question-five/movie-night.jpg"
import DrinksWithFriends from "../../images/question-five/drinks.jpg"
import HeadingToAClub from "../../images/question-five/club.jpg"
import AFunActivity from "../../images/question-five/activity.jpg"

const QuestionFive = ({ register, errors }) => {
  const questionFiveRef = useRef(null)
  function goToNext() {
    if (questionFiveRef.current) {
      questionFiveRef.current.scrollIntoView()
      window.scrollBy(0, questionFiveRef.current.offsetHeight + 30)
    }
  }
  return (
    <>
      <div className="bg-question-type-one" ref={questionFiveRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="row no-gutters">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="question-title-hldr d-flex flex-column align-items-center">
                    <div className="question-number">5</div>
                    <h2 className="question-title my-4 text-white text-center">
                      Your favourite night out is…
                    </h2>
                    {/* <span className="questions-left text-primary text-center bg-yellow px-3 py-1">
                      3 Questions Left
                    </span> */}
                  </div>
                </div>
              </div>

              <div className="row no-gutters">
                <div className="col-12">
                  {errors?.questionFive && (
                    <span className="error d-inline-block mb-2 text-yellow">
                      Please select at least one option
                    </span>
                  )}
                </div>
                <div className="col-6">
                  <label className="checkbox-container pr-2 mb-3 w-100">
                    <input
                      name="questionFive"
                      type="radio"
                      value="Movie night"
                      {...register('questionFive', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={MovieNight} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Movie night
                      </p>
                    </div>
                  </label>
                </div>
                <div className="col-6">
                  <label className="checkbox-container pl-2 mb-3 w-100">
                    <input
                      name="questionFive"
                      type="radio"
                      value="Drinks with friends"
                      {...register('questionFive', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img
                          className="img-fluid"
                          src={DrinksWithFriends}
                          alt=""
                        />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Drinks with friends
                      </p>
                    </div>
                  </label>
                </div>

                <div className="col-6">
                  <label className="checkbox-container pr-2 mb-0 w-100">
                    <input
                      name="questionFive"
                      type="radio"
                      value="Heading to a club"
                      {...register('questionFive', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img
                          className="img-fluid"
                          src={HeadingToAClub}
                          alt=""
                        />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Heading to a club
                      </p>
                    </div>
                  </label>
                </div>
                <div className="col-6">
                  <label className="checkbox-container pl-2 mb-0 w-100">
                    <input
                      name="questionFive"
                      type="radio"
                      value="A fun activity"
                      {...register('questionFive', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={AFunActivity} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        A fun activity
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="text-center mt-5">
                <span className="questions-left text-primary text-center bg-yellow px-3 py-1">
                  3 Questions Left
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionFive
