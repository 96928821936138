import React from "react"

import Win from "../images/win.png"

const EmailField = ({ register, errors }) => {
  return (
    <>
      <div className="bg-film-two bg-film-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center text-white">
              {/* <h2 className="my-4 mb-lg-3 text-white text-center">
                Chance to win a KitchenAid Stand mixer and Maltesers bundle
              </h2>
              <h5 className="mb-5 mb-lg-4">
                To take part, enter your email details to be entered into the
                prize draw AND receive your special personalised recipe plus
                instructions on how to make the world’s very first Maltesers
                Artificial Intelligence recipe. No purchase necessary to enter.
              </h5>
              

              <figure className="mb-5 position-relative competitive-img">
                <img className="img-fluid" src={Win} alt="" />
              </figure> */}

              {/* <h2 className="mb-4">
                Grab <span className="text-yellow">Your Recipe!</span>
              </h2> */}
              <h5 className="mb-3">Enter your email below</h5>
              <div className="email-field-hldr mb-4">
                {errors?.email && (
                  <span className="error d-block mb-2 text-yellow text-left">
                    {errors.email.message}
                  </span>
                )}
                <div className="d-flex flex-column flex-md-row">
                  <input
                    type="text"
                    name="email"
                    className="form-control rounded-0 text-center text-md-left"
                    placeholder="Type Here"
                    {...register("email", {
                      required: "Email ID is Required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid Email Address",
                      },
                    })}
                    // ref={register({
                    //   required: "Email ID is Required",
                    //   pattern: {
                    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    //     message: "Invalid Email Address",
                    //   },
                    // })}
                  />
                  <button
                    id="btn-form-submit"
                    className="btn btn-block btn-secondary rounded-0"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
                <div className="form-check text-left mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="maltesers"
                    // ref={register}
                    {...register("maltesers")}
                    id="maltesers"
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="maltesers"
                  >
                    I would like to receive personalised marketing offers from
                    Maltesers via email phone and other electronic channels. You
                    can withdraw this consent at any time
                  </label>
                </div>
                <div className="form-check text-left mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="wrigly"
                    // ref={register}
                    {...register("wrigly")}
                    id="wrigly"
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="wrigly"
                  >
                    I would like to receive personalised marketing offers from
                    other Mars Wrigley's brands via email phone and other
                    electronic channels. You can withdraw this consent at any
                    time
                  </label>
                </div>
                <div className="form-check text-left mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="research"
                    // ref={register}
                    {...register("research")}
                    id="research"
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="research"
                  >
                    Allow my data to be used for research to enhance the
                    effectiveness of our products and services to you, including
                    matching my data. You can withdraw this consent at any time
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailField
