import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
// import { useForm } from "react-hook-form"
import Cookies from "universal-cookie"
// import { Row, Col, Container, Form, Button } from "react-bootstrap"

// import { detectAnyAdblocker } from "just-detect-adblock"

// Components
import Layout from "../../components/MaltesersBakeAgainstTheMachineComponents/components/layout"
import SEO from "../../components/MaltesersBakeAgainstTheMachineComponents/components/seo"
import Header from "../../components/MaltesersBakeAgainstTheMachineComponents/components/header"
import LogoBakeAgainst from "../../components/MaltesersBakeAgainstTheMachineComponents/components/logo-bake-against"
import ChocolateBarImg from "../../components/MaltesersBakeAgainstTheMachineComponents/components/chocolate-bar-img"
import Agegate from "../../components/MaltesersBakeAgainstTheMachineComponents/components/agegate"
import QuizForm from "../../components/MaltesersBakeAgainstTheMachineComponents/components/quiz-form"
import SuccessMessage from "../../components/MaltesersBakeAgainstTheMachineComponents/components/success-message"
import Footer from "../../components/MaltesersBakeAgainstTheMachineComponents/components/footer"

// Questions Components
// import QuestionOne from "../components/questions/question-one"
// import QuestionTwo from "../components/questions/question-two"
// import QuestionThree from "../components/questions/question-three"
// import QuestionFour from "../components/questions/question-four"
// import QuestionFive from "../components/questions/question-five"
// import QuestionSix from "../components/questions/question-six"
// import QuestionSeven from "../components/questions/question-seven"
// import QuestionEight from "../components/questions/question-eight"

const IndexPage = () => {
  const [ageGateShow, setAgeGateShow] = useState(true)
  const [showQuizForm, setShowQuizForm] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  // const [showAdBlock, setShowAdBlock] = useState(false)

  // let ageCookie = new Cookies()

  // if (ageCookie.get("isOldEnough") === "yes") {
  //   setAgeGateShow(false)
  // } else {
  //   ageCookie.set("isOldEnough", "no", { path: "/" })
  //   setAgeGateShow(true)
  // }

  // useEffect(() => {
  //   if (ageCookie.get("isOldEnough") === "yes") {
  //     setAgeGateShow(false)
  //     setShowQuizForm(true)
  //   } else {
  //     ageCookie.set("isOldEnough", "no", { path: "/" })
  //     setAgeGateShow(true)
  //   }

  // detectAnyAdblocker().then(detected => {
  //   if (detected) {
  //     // an adblocker is detected
  //     //alert("Detected")
  //     console.log("Detected")
  //     setShowAdBlock(true)
  //   }
  // })
  // }, [])

  // const { register, handleSubmit, errors } = useForm()
  // const { register, handleSubmit, watch, errors } = useForm({
  //   defaultValues: {
  //     questionOne: [],
  //     questionTwo: [],
  //     questionThree: [],
  //     questionFour: [],
  //     questionFive: [],
  //     questionSix: [],
  //     questionSeven: [],
  //     questionEight: [],
  //     consent: false,
  //   },
  // })

  // const onSubmit = data => {
  //   console.log(data)
  // }

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="../MaltesersBakeAgainstTheMachine/css/layout.css" />
        <link rel="stylesheet" href="../MaltesersBakeAgainstTheMachine/css/layout.media.css" />
      </Helmet>
      <Layout pageInfo={{ pageName: "index" }}>
        <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />

        <div
          className={`${
            showQuizForm ? "bg-top-section-home" : "bg-top-section"
          } age-gate-padding position-relative`}
        >
          <Header />
          <div className="text-center">
            <LogoBakeAgainst />
            {ageGateShow && (
              <Agegate
                setAgeGateShow={setAgeGateShow}
                setShowQuizForm={setShowQuizForm}
              />
            )}
            {showSuccess && (
              <SuccessMessage
                setShowSuccess={setShowSuccess}
                setShowQuizForm={setShowQuizForm}
              />
            )}
          </div>
          <ChocolateBarImg />
        </div>

        {showQuizForm && (
          <QuizForm
            setShowSuccess={setShowSuccess}
            setShowQuizForm={setShowQuizForm}
          />
        )}

        <Footer showQuizForm={showQuizForm} />
        {/* {showAdBlock && (
        <div className="show-block d-flex justify-content-center align-items-center text-center">
          <h3 className="m-0">Please Disable Ad Block to Submit the Quiz</h3>
        </div>
      )} */}
      </Layout>
    </>
  )
}

export default IndexPage
