import React, { useEffect, useRef } from "react"

const SuccessMessage = props => {
  const successMessageRef = useRef(null)
  useEffect(() => {
    successMessageRef.current.scrollIntoView({
      behavior: "smooth",
    })
    window.scrollBy(0, -150)
  }, [])
  return (
    <>
      <div className="success-text text-white px-5" ref={successMessageRef}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center text-white">
              <h2 className="text-yellow">Success!</h2>
              <h4 className="my-4 mt-md-0">
                Your submission has been successful. Check your inbox for your
                ultimate Maltesers recipe!
              </h4>
              <button
                type="button"
                className="btn btn-primary border-0 rounded-0 mt-3 text-uppercase"
                onClick={() => {
                  props.setShowSuccess(false)
                  props.setShowQuizForm(true)
                }}
              >
                Take Quiz Again
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuccessMessage
