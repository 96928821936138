import React, { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"

// Questions Components
import QuizIntro from "./quiz-intro"
import QuestionOne from "./questions/question-one"
import QuestionTwo from "./questions/question-two"
import QuestionThree from "./questions/question-three"
import QuestionFour from "./questions/question-four"
import QuestionFive from "./questions/question-five"
import QuestionSix from "./questions/question-six"
import QuestionSeven from "./questions/question-seven"
import QuestionEight from "./questions/question-eight"
// import Competition from "../components/competition"
import EmailField from "./email-field"

import DividerLine from "./divider-line"

const QuizForm = props => {
  const quizIntroRef = useRef(null)
  const { register, handleSubmit, reset, watch, errors } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      email: "",
      maltesers: false,
      questionEight: "",
      questionFive: "",
      questionFour: "",
      questionOne: "",
      questionSeven: "",
      questionSix: "",
      questionThree: "",
      questionTwo: "",
      research: false,
      wrigly: false,
    },
  })

  const watchAllFields = watch()

  const onSubmit = data => {
    console.log(data)
    //console.log(data["questionOne"])

    // window.dataLayer = window.dataLayer || []
    // window.dataLayer.push({
    //   event: "quiz",
    //   status: "submit",
    //   question1: data["questionOne"],
    //   question2: data["questionTwo"],
    //   question3: data["questionThree"],
    //   question4: data["questionFour"],
    //   question5: data["questionFive"],
    //   question6: data["questionSix"],
    //   question7: data["questionSeven"],
    //   question8: data["questionEight"],
    // })

    // window.dataLayer.push({
    //   event: "optin",
    //   maltesers: data["maltesers"],
    //   wrigly: data["wrigly"],
    //   research: data["research"],
    // })

    props.setShowQuizForm(false)
    props.setShowSuccess(true)
    reset(data)
  }

  const onError = (errors, e) => {
    //console.log(errors, e)
    setTimeout(() => {
      // let errorFocus =
      //   document.querySelector(".error").parentElement.parentElement
      //     .parentElement.parentElement
      console.log(errors)
      // errorFocus.scrollIntoView()
      window.scrollBy(0, -30)
    }, 100)
  }

  useEffect(() => {
    quizIntroRef.current.scrollIntoView({
      behavior: "smooth",
    })
    window.scrollBy(0, -20)
  }, [])

  return (
    <>
      <div ref={quizIntroRef}>
        <QuizIntro />
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="question-form-hldr">
            <QuestionOne register={register} errors={errors} />
            <DividerLine />
            <QuestionTwo register={register} errors={errors} />
            <DividerLine />
            <QuestionThree register={register} errors={errors} />
            <DividerLine />
            <QuestionFour register={register} errors={errors} />
            <DividerLine />
            <QuestionFive register={register} errors={errors} />
            <DividerLine />
            <QuestionSix register={register} errors={errors} />
            <DividerLine />
            <QuestionSeven register={register} errors={errors} />
            <DividerLine />
            <QuestionEight register={register} errors={errors} />
            {/* <DividerLine />
            <Competition /> */}
          </div>
          <EmailField register={register} errors={errors} />
        </form>
      </div>
    </>
  )
}

export default QuizForm
