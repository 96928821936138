import React, { useRef } from "react"

// Images
import Red from "../../images/question-three/red.jpg"
import Brown from "../../images/question-three/brown.jpg"
import Pink from "../../images/question-three/pink.jpg"
import Yellow from "../../images/question-three/yellow.jpg"

const QuestionThree = ({ register, errors }) => {
  const questionThreeRef = useRef(null)
  function goToNext() {
    if (questionThreeRef.current) {
      questionThreeRef.current.scrollIntoView()
      window.scrollBy(0, questionThreeRef.current.offsetHeight + 30)
    }
  }
  return (
    <>
      <div className="bg-question-type-one" ref={questionThreeRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="row no-gutters">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="question-title-hldr d-flex flex-column align-items-center">
                    <div className="question-number">3</div>
                    <h2 className="question-title my-4 text-white text-center">
                      Your favourite colour is…
                    </h2>
                    {/* <span className="questions-left text-primary text-center bg-yellow px-3 py-1">
                      5 Questions Left
                    </span> */}
                  </div>
                </div>
              </div>

              <div className="row no-gutters">
                <div className="col-12">
                  {errors?.questionThree && (
                    <span className="error d-inline-block mb-2 text-yellow">
                      Please select at least one option
                    </span>
                  )}
                </div>
                <div className="col-6">
                  <label className="checkbox-container pr-2 mb-3 w-100">
                    <input
                      name="questionThree"
                      type="radio"
                      value="Red"
                      {...register('questionThree', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={Red} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Red
                      </p>
                    </div>
                  </label>
                </div>
                <div className="col-6">
                  <label className="checkbox-container pl-2 mb-3 w-100">
                    <input
                      name="questionThree"
                      type="radio"
                      value="Brown"
                      {...register('questionThree', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={Brown} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Brown
                      </p>
                    </div>
                  </label>
                </div>

                <div className="col-6">
                  <label className="checkbox-container pr-2 mb-0 w-100">
                    <input
                      name="questionThree"
                      type="radio"
                      value="Pink"
                      {...register('questionThree', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={Pink} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Pink
                      </p>
                    </div>
                  </label>
                </div>
                <div className="col-6">
                  <label className="checkbox-container pl-2 mb-0 w-100">
                    <input
                      name="questionThree"
                      type="radio"
                      value="Yellow"
                      {...register('questionThree', { required: true })}
                      // ref={register({ required: true })}
                      onChange={() => goToNext()}
                    />
                    <div className="checkmark">
                      <figure className="mb-0 position-relative">
                        <img className="img-fluid" src={Yellow} alt="" />
                      </figure>
                      <p className="text-center text-white mb-0 answer-title">
                        Yellow
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="text-center mt-5">
                <span className="questions-left text-primary text-center bg-yellow px-3 py-1">
                  5 Questions Left
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionThree
