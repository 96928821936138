import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LogoBakeAgainst = () => {
  const data = useStaticQuery(graphql`
    query {
      logoBakeAgainst: file(
        relativePath: { eq: "bake-against-the-machine.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <figure className="py-4 pt-md-5 m-auto logo-bake-against">
        <Img fluid={data.logoBakeAgainst.childImageSharp.fluid} alt="" />
      </figure>
    </>
  )
}

export default LogoBakeAgainst
